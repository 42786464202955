import variables from "./variables";

const buttons = {
  // Buttons
  MuiButton: {
    root: {
      fontFamily: variables.typography.headerFont,
      fontWeight: variables.typography.fontWeightBold,
      lineHeight: variables.typography.longform.lineHeight,
      padding: [[variables.spacing, variables.spacing * 2]],
      minWidth: undefined,
      whiteSpace: "nowrap",
      transition: undefined,
      "&$disabled": {
        color: undefined,
        opacity: 0.5,
      },
      "&:hover": {
        backgroundColor: undefined,
      },
    },
    text: {
      color: variables.palette.text.secondary,
      padding: [[2, 8]],
      margin: [[-2, -8]],
      "&:hover": {
        color: variables.palette.text.primary,
      },
    },
    textPrimary: {
      "&:hover": {
        color: variables.palette.primary.main,
      },
    },
    contained: {
      backgroundColor: variables.palette.background.paper,
      boxShadow: `inset 0 0 0 1px ${variables.borders.palette.base}`,
      height: variables.shape.actionHeight,
      "&:hover": {
        backgroundColor: undefined,
        boxShadow: undefined,
        "@media (hover: none)": {
          boxShadow: undefined,
        },
      },
      "&:active": {
        boxShadow: undefined,
      },
      "&$disabled": {
        color: undefined,
        boxShadow: undefined,
        backgroundColor: undefined,
      },
    },
    containedPrimary: {
      backgroundColor: variables.palette.primary.main,
      "&:hover": {
        backgroundColor: variables.palette.primary.main,
        "@media (hover: none)": {
          backgroundColor: undefined,
        },
      },
    },
    containedSizeLarge: {
      fontSize: variables.typography.heading.fontSize,
      padding: [[0, variables.spacing * 3]],
      height: variables.shape.actionHeightLarge,
    },
  },
  MuiIconButton: {
    root: {
      color: variables.palette.text.primary,
      padding: variables.spacing,
    },
    edgeEnd: {
      marginRight: -1 * variables.spacing,
    },
  },
  MuiButtonGroup: {
    contained: {
      boxShadow: "none",
      "&:hover, &:active": {
        boxShadow: "none",
      },
    },
  },
  MuiFab: {
    root: {
      width: variables.shape.actionHeightLarge,
      height: variables.shape.actionHeightLarge,
    },
  },
};

export default buttons;
