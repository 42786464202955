import { createMuiTheme } from "@material-ui/core/styles";
import variables from "./variables";
import palette from "./palette";
import overrides from "./overrides";
import typography from "./typography";

const theme = createMuiTheme({
  palette,
  overrides,
  typography,
  props: {
    MuiAppBar: {
      elevation: 0,
    },
    MuiPaper: {
    },
    MuiAvatar: {
      variant: "circle",
    },
    MuiDialog: {
      transitionDuration: 0,
      maxWidth: "xs",
      scroll: "body",
      PaperProps: {
        elevation: 2,
      },
    },
    MuiSnackbar: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
    },
    MuiSnackbarContent: {
      elevation: 0,
    },
    MuiPopover: {
      elevation: 2,
    },


    MuiList: {
      disablePadding: true,
    },
    MuiListItem: {
      disableGutters: false,
    },
    MuiListItemText: {
      secondaryTypographyProps: {
        variant: "body1",
      },
    },
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
    MuiButton: {
      variant: "contained",
    },
    MuiChip: {
      size: "small",
    },
    MuiGrid: {
      spacing: 2,
    },


    MuiInputLabel: {
    },
    MuiFormHelperText: {
    },
    MuiTextField: {
      variant: "outlined",
    },
    MuiSelect: {
      variant: "outlined",
    },
    MuiOutlinedInput: {
      notched: false,
    },
    MuiCheckbox: {
      color: "primary",
    },
    MuiRadio: {
      color: "primary",
    },
    MuiTooltip: {
      placement: "top",
      enterTouchDelay: 0,
      leaveTouchDelay: 1500,
      TransitionProps: {
        timeout: 0,
      },
    },
    MuiTypography: {
      display: "block",
    },
    MuiTabs: {
      textColor: "primary",
      indicatorColor: "primary",
      variant: "scrollable",
    },
  },
  breakpoints: {
    values: variables.breakpoints,
  },
  layout: {
    containerLarge: "1440px",
    containerMedium: "1024px",
    containerSmall: "768px",
    containerTiny: "368px",
  },
  shape: {
    actionHeightSmall: variables.shape.actionHeightSmall,
    actionHeight: variables.shape.actionHeight,
    borderRadiusLarge: variables.shape.borderRadius.large,
  },
  shadows: [
    "none",
    "0 0 0 1px rgba(0,0,0,0.15), 0 1px 2px rgba(0,0,0,0.15), 0 8px 16px -8px rgba(0,0,0,0.15)",
    "0 0 0 1px rgba(0,0,0,0.15), 0 2px 4px rgba(0,0,0,0.3), 0 16px 32px -8px rgba(0,0,0,0.3)",
  ],
});

export default theme;
