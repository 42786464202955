import React from "react";
import App from "next/app";
import Head from "next/head";
import Router from "next/router";
import { Provider } from "react-redux";
import NProgress from "nprogress";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import FullStory from "react-fullstory";
// import Intercom from "react-intercom";
import * as Sentry from "@sentry/node";
import { analytics } from "~/src/api";
import * as PLUGINS from "~/src/constants/plugins";
import theme from "~/src/theme/theme";
import initStore from "~/src/store";
import "~/src/styles/global.css";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

const store = initStore();

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", (url) => {
  analytics.pageview(url);
  NProgress.done();
});
Router.events.on("routeChangeError", () => NProgress.done());
Router.events.on("routeChangeComplete", () => { window.scrollTo(0, 0); });

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: PLUGINS.SENTRY_ID,
  });
}

class BolstrApp extends App {
  constructor(props) {
    super(props);
    this.store = store;
  }

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <Provider store={this.store}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Head>
            <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
          </Head>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            {process.env.NODE_ENV !== "development" && <FullStory org={PLUGINS.FULLSTORY_ID} />}
            {/* process.env.NODE_ENV !== "development" && <Intercom appID={PLUGINS.INTERCOM_ID} /> */}
            <Component {...pageProps} />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </Provider>
    );
  }
}

export default BolstrApp;
