import { fade } from "@material-ui/core/styles/colorManipulator";
import variables from "./variables";
import breakpointsTheme from "./breakpoints";
import buttons from "./buttons";

const overrides = {
  ...buttons,

  // Cards
  MuiCard: {
    root: {
      overflow: "visible",
    },
  },
  MuiCardContent: {
    root: {
      "&:last-child": {
        paddingBottom: undefined,
      },
    },
  },
  MuiPaper: {
    elevation2: {

    },
    rounded: {
      borderRadius: variables.shape.borderRadius.large,
    },
  },

  // Icons
  MuiSvgIcon: {
    root: {
      display: "block",
      fontSize: 24,
    },
    fontSizeLarge: {
      fontSize: 36,
    },
  },

  // Lists
  MuiList: {
    root: {
      margin: [[-3 * variables.spacing, -2 * variables.spacing]],
    },
    padding: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  MuiListItem: {
    root: {
      paddingTop: variables.spacing * 3,
      paddingBottom: variables.spacing * 3,
      "& + $root": {
        paddingTop: 0,
      },
    },
    button: {
      transition: undefined,
    },
  },
  MuiListItemAvatar: {
    alignItemsFlexStart: {
      marginTop: 2,
    },
  },
  MuiListItemIcon: {
    root: {
      color: variables.palette.text.primary,
      minWidth: 40,
      alignSelf: "flex-start",
    },
  },
  MuiListItemText: {
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
    multiline: {
      marginTop: undefined,
      marginBottom: undefined,
    },
  },
  MuiMenu: {
    list: {
      margin: 0,
    },
  },
  MuiMenuItem: {
    root: {
      minHeight: undefined,
      paddingTop: 12,
      paddingBottom: 12,
      "& + $root": {
        paddingTop: 12,
      },
    },
  },

  // Inputs
  MuiFormLabel: {
    root: {
      display: "block",
      color: variables.palette.text.primary,
      lineHeight: undefined,
    },
  },
  MuiFormControl: {
    root: {
      display: "flex",
      "& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder": {
        opacity: [["0.42"], "!important"],
      },
      "& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-moz-placeholder": {
        opacity: [["0.42"], "!important"], // Firefox 19+
      },
      "& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input:-ms-input-placeholder": {
        opacity: [["0.42"], "!important"], // IE 11
      },
      "& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-ms-input-placeholder": {
        opacity: [["0.42"], "!important"], // Edge
      },
    },
  },
  MuiFormHelperText: {
    root: {
      fontSize: variables.typography.base.fontSize,
      lineHeight: variables.typography.base.lineHeight,
      marginTop: breakpointsTheme.spacing(0.5),
    },
    contained: {
      marginLeft: undefined,
      marginRight: undefined,
    },
  },
  MuiInputLabel: {
    root: {
      marginBottom: variables.spacing / 2,
      fontSize: variables.typography.base.fontSize,
      lineHeight: variables.typography.base.lineHeight,
    },
    outlined: {
      position: "static",
      transform: "none",
      "&$shrink": {
        transform: "none",
      },
    },
  },
  MuiInputBase: {
    root: {
      fontSize: variables.typography.base.fontSize,
      lineHeight: variables.typography.base.lineHeight,
      "&$disabled": {
        backgroundColor: fade(variables.palette.text.primary, 0.1),
      },
      "@media (max-device-width: 600px)": {
        fontSize: 16, // Prevent zooming in on iPhones.
      },
    },
    input: {
      display: "flex",
    },
  },
  MuiInputAdornment: {
    positionStart: {
      color: variables.palette.text.secondary,
    },
  },
  MuiOutlinedInput: {
    notchedOutline: {
      borderColor: variables.borders.palette.base,
    },
    root: {
      display: "flex",
      background: "white",
      "&:hover $notchedOutline": {
        borderColor: variables.borders.palette.dark,
      },
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        "&:hover $notchedOutline": {
          borderColor: variables.borders.palette.base,
        },
      },
      "&$focused $notchedOutline": {
        borderColor: variables.palette.primary.main,
        borderWidth: 1,
        boxShadow: `0 0 0 2px ${fade(variables.palette.primary.main, 0.2)}`,
      },
      "&$error $notchedOutline": {
      },
      "&$disabled $notchedOutline": {
        borderColor: undefined,
      },
    },
    input: {
      height: variables.shape.actionHeight,
      padding: [[0, 0, 1, 12]],
      boxSizing: "border-box",
    },
    multiline: {
      padding: [[8, 12, 8]],
      minHeight: variables.shape.actionHeightLarge,
    },
    inputMultiline: {
      minHeight: 32,
    },
  },
  MuiSelect: {
    select: {
      paddingBottom: 0,
      "&:focus": {
        backgroundColor: undefined,
      },
    },
    selectMenu: {
      display: "flex",
      alignItems: "center",
      height: undefined,
      "&$selectMenu": {
        paddingRight: breakpointsTheme.spacing(4),
      },
    },
  },
  MuiAutocomplete: {
    input: {
      height: "auto",
    },
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"]': {
        "& $input": {
          padding: undefined,
          "&:first-child": {
            padding: undefined,
          },
        },
      },
    },
  },

  // Choices
  MuiRadio: {
    root: {
      padding: 0,
    },
  },
  MuiCheckbox: {
    root: {
      padding: 0,
    },
  },
  MuiFormGroup: {
    root: {
      margin: [[breakpointsTheme.spacing(-1), 0]],
    },
    row: {
      "& > *": {
        width: "100%",
        "@media (min-device-width: 400px)": {
          width: "50%",
        },
      },
    },
  },
  MuiFormControlLabel: {
    root: {
      display: "flex",
      alignItems: "flex-start",
      padding: [[breakpointsTheme.spacing(), 0]],
      marginLeft: undefined,
      marginRight: undefined,
    },
    label: {
      marginTop: 1,
      marginLeft: breakpointsTheme.spacing(),
    },
  },

  // Dialogs
  MuiDialog: {
    paper: {
      width: "100%",
      margin: breakpointsTheme.spacing(2),
    },
    scrollPaper: {
      alignItems: "flex-start",
      [breakpointsTheme.breakpoints.down("xs")]: {
        alignItems: "flex-end",
      },
    },
    paperScrollPaper: {
      maxHeight: `calc(100% - ${breakpointsTheme.spacing(4)}px)`,
      [breakpointsTheme.breakpoints.down("xs")]: {
        margin: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    paperScrollBody: {
      margin: 0,
    },
  },
  MuiDialogContent: {
    root: {
      padding: breakpointsTheme.spacing(2),
    },
    dividers: {
      padding: undefined,
      borderBottom: undefined,
    },
  },

  // Tabs
  MuiTabs: {
    root: {
      minHeight: variables.shape.actionHeight,
      boxShadow: `inset 0 -1px ${variables.borders.palette.base}`,
    },
  },
  MuiTab: {
    root: {
      minWidth: undefined,
      minHeight: variables.shape.actionHeight,
      fontSize: variables.typography.base.fontSize,
      fontWeight: variables.typography.fontWeightBold,
      padding: 0,
      [breakpointsTheme.breakpoints.up("sm")]: {
        fontSize: undefined,
        minWidth: undefined,
      },
      "& + $root": {
        marginLeft: breakpointsTheme.spacing(2),
      },
    },

  },

  // Misc
  MuiAppBar: {
    positionStatic: {
      transform: "none",
    },
  },
  MuiChip: {
    root: {
      fontSize: variables.typography.base.fontSize,
      lineHeight: variables.typography.base.lineHeight,
      borderRadius: variables.shape.borderRadius.base,
    },
    sizeSmall: {
      height: 20,
    },
    colorPrimary: {
      color: undefined,
      backgroundColor: fade(variables.palette.success.main, 0.25),
    },
    outlined: {
      borderRadius: 100,
      borderColor: variables.borders.palette.base,
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: variables.borders.palette.base,
    },
    vertical: {
      height: 20,
    },
    light: {
      backgroundColor: fade("#fff", 0.2),
    },
    inset: {
      marginTop: -1,
      marginBottom: -1,
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: variables.typography.caption.fontSize,
      lineHeight: variables.typography.caption.lineHeight,
      backgroundColor: fade(variables.palette.text.primary, 0.9),
    },
    tooltipPlacementTop: {
      margin: [[breakpointsTheme.spacing(0.5), 0]],
      [breakpointsTheme.breakpoints.up("sm")]: {
        margin: undefined,
      },
    },
  },

  MuiTableCell: {
    head: {
      color: variables.palette.text.secondary,
      whiteSpace: "nowrap",
    },
  },


  MuiPickersBasePicker: {
    pickerView: {
      minWidth: 304,

    },
  },
  MuiPickersModal: {
    dialogRoot: {
      minWidth: 304,
      maxWidth: 325,
    },
    withAdditionalAction: {
      display: "none",
    },
  },

  MuiAvatar: {
    fallback: {
      width: 36,
    },
    colorDefault: {
      fontSize: 24,
      fontWeight: variables.typography.fontWeightBold,
      color: variables.palette.text.primary,
      border: `1px solid ${variables.borders.palette.base}`,
      backgroundColor: "white",
    },
  },

  MuiTypography: {
    gutterBottom: {
      marginBottom: breakpointsTheme.spacing(),
    },
  },

  MuiLinearProgress: {
    root: {
      borderRadius: 100,
    },
    colorPrimary: {
      backgroundColor: fade(variables.palette.primary.main, 0.2),
    },
    colorSecondary: {
      backgroundColor: fade(variables.palette.success.main, 0.2),
    },
    barColorSecondary: {
      backgroundColor: variables.palette.success.main,
    },
  },
};

export default overrides;
