import variables from "./variables";
import breakpointsTheme from "./breakpoints";

const typography = {
  fontFamily: variables.typography.textFont,
  fontSize: variables.typography.base.fontSize,
  fontWeightRegular: variables.typography.fontWeightRegular,
  fontWeightMedium: variables.typography.fontWeightMedium,
  fontWeightBold: variables.typography.fontWeightBold,
  caption: {
    fontSize: variables.typography.caption.fontSize,
    lineHeight: variables.typography.caption.lineHeight,
  },
  body1: {
    fontSize: variables.typography.base.fontSize,
    lineHeight: variables.typography.base.lineHeight,
  },
  body2: {
    fontSize: variables.typography.longform.fontSize,
    lineHeight: variables.typography.longform.lineHeight,
  },
  button: {
    textTransform: "none",
    fontWeight: 600,
  },
  h1: {
    fontFamily: variables.typography.headerFont,
    fontSize: 28,
    fontWeight: variables.typography.header.fontWeight,
    lineHeight: 1.1,
    [breakpointsTheme.breakpoints.up("sm")]: {
      fontSize: 36,
    },
    [breakpointsTheme.breakpoints.up("md")]: {
      fontSize: 48,
    },
  },
  h2: {
    fontFamily: variables.typography.headerFont,
    fontSize: 22,
    fontWeight: variables.typography.header.fontWeight,
    lineHeight: variables.typography.header.lineHeight,
    [breakpointsTheme.breakpoints.up("sm")]: {
      fontSize: 22,
    },
    [breakpointsTheme.breakpoints.up("md")]: {
      fontSize: 28,
    },
  },
  h3: {
    fontSize: 18,
    fontWeight: variables.typography.fontWeightRegular,
    lineHeight: variables.typography.header.lineHeight,
    [breakpointsTheme.breakpoints.up("sm")]: {
      fontSize: 18,
    },
    [breakpointsTheme.breakpoints.up("md")]: {
      fontSize: 22,
    },
  },
  h4: {
    fontSize: variables.typography.title.fontSize,
    fontWeight: variables.typography.title.fontWeight,
    lineHeight: variables.typography.title.lineHeight,
    fontFamily: variables.typography.headerFont,
  },
  h5: {
    fontSize: variables.typography.title.fontSize,
    fontWeight: variables.typography.title.fontWeight,
    lineHeight: variables.typography.title.lineHeight,
    fontFamily: variables.typography.headerFont,
  },
  h6: {
    fontSize: variables.typography.heading.fontSize,
    fontWeight: variables.typography.heading.fontWeight,
    lineHeight: variables.typography.heading.lineHeight,
  },
};

export default typography;
