import { createMuiTheme } from "@material-ui/core/styles";
import variables from "./variables";

const breakpointsTheme = createMuiTheme({
  breakpoints: {
    values: variables.breakpoints,
  },
});

export default breakpointsTheme;
