import { fade } from "@material-ui/core/styles/colorManipulator";

const variables = {};

const typography = {
  textFont: "Roboto, -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Helvetica Neue, sans-serif",
  headerFont: "Work Sans, -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Helvetica Neue, sans-serif",
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 500,
  base: {
    fontSize: 15,
    lineHeight: 1.5,
  },
  longform: {
    fontSize: 15,
    lineHeight: 1.7,
  },
  caption: {
    fontSize: 12,
    lineHeight: 1.34,
  },
  header: {
    lineHeight: 1.33,
    fontWeight: 800,
  },
  title: {
    fontSize: 22,
    lineHeight: 1.33,
    fontWeight: 500,
  },
  heading: {
    fontSize: 17,
    lineHeight: 1.33,
    fontWeight: 500,
  },
};

const palette = {
  background: {
    paper: "#FFF",
    default: "#F5F1EB",
  },
  text: {
    primary: "#262627",
    secondary: fade("#262627", 0.66),
    disabled: fade("#262627", 0.33),
    hint: fade("#262627", 0.33),
  },
  primary: {
    light: "#1471c1",
    main: "#1471c1",
    dark: "#1471c1",
  },
  success: {
    main: "#08774e",
  },
  warning: {
    main: "#f7b02b",
  },
  danger: {
    main: "#c11a49",
  },
};

const borders = {
  palette: {
    light: `${fade(palette.text.primary, 0.1)}`,
    base: `${fade(palette.text.primary, 0.15)}`,
    dark: `${fade(palette.text.primary, 0.3)}`,
    focus: palette.primary,
  },
  width: "1px",
};

const shape = {
  borderRadius: {
    small: 2,
    base: 4,
    large: 6,
  },
  actionHeightSmall: 36,
  actionHeight: 40,
  actionHeightLarge: 48,
};

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 1000,
  lg: 1280,
  xl: 1440,
};

variables.typography = typography;
variables.palette = palette;
variables.borders = borders;
variables.shape = shape;
variables.spacing = 8;
variables.breakpoints = breakpoints;

export default variables;
